html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 10pt;
    font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif;
}

body, td, ol, ul, li, p {
    font-size: 10pt;
    color: #222;
    font-family: Verdana, Arial, Helvetica, Tahoma, sans-serif;
}

.page-title {
    margin: 24px 120px;
    font-size: 20pt;
    color: #6F0000
}

.btn {
    cursor: pointer;
    border: 1px double #9a9996;
    font-size: 12px;
    color: #dfdfdf;
    background-color:#504F4C;
}

.btn:hover {
    color: #CECECE;
    background-color:#393937;
}

a,a:visited {
    text-decoration: none;
    FONT-WEIGHT: bold;
    color: #003388;
}

a:active {
    color: #6F0000
}

a:hover	{
    color: #0066FF
}

label {
    cursor: pointer;
}